import { create } from 'apisauce';
import { HUBSPOT_API_FORM_GUI, HUBSPOT_API_KEY } from '../utils';
import { navigate } from 'gatsby';
const HTTP = create({
  baseURL: 'https://api.hsforms.com/submissions/v3/integration/submit/',
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    'X-Frame-Options': 'SAMEORIGIN',
  },
});

export interface HubspotFormI {
  firstName: string;
  lastName: string;
  email: string;
}

interface ContactUsFormI extends HubspotFormI {
  phone: string;
  company: string;
  organization_type: string;
  message?: string;
}

const api = {
  sendForm: (
    field_values: HubspotFormI | ContactUsFormI,
    formId: string = HUBSPOT_API_FORM_GUI,
    redirectOnSuccess: boolean = true
  ) => {
    const values = Object.entries(field_values).map((field) => ({
      name: field[0],
      value: field[1],
    }));
    const data = {
      fields: values,
    };
    HTTP.post(`${HUBSPOT_API_KEY}/${formId}`, data, {}).then((response) => {
      // console.log('response', response);
      if (response.status === 200 && redirectOnSuccess) {
        navigate('/thankyou');
      }
    });
  },
};

export { api };
